<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-progress-circular
        v-if="showDashboardLoader"
        indeterminate
      />
      <v-alert
        v-if="showStatusMessage"
        id="dashboardStatusMessage"
        :type="statusMessageType"
        class="mt-2"
      >
        {{ statusMessage }}
      </v-alert>
    </v-row>
    <v-row
      :key="chartData.length"
    >
      <v-col
        v-for="(chart, i) in chartData"
        :key="`chart-${i}`"
        cols="12"
        lg="6"
      >
        <material-chart-js-card
          :id="`dashboard-${i}`"
          :key="i"
          :chart-initial-data="chart"
          :is-dashboard="true"
          :extend-height="true"
        />
      </v-col>
    </v-row>
    <chart-dialog
      v-if="showChartDialog"
      :key="dialogId"
      :chart-initial-data="chartDialogData"
      @close-dialog="dismissChartDialog"
    />
  </v-container>
</template>

<script>
  import dashboardApi from '@/api/dashboard.js'
  import common from '@/api/common.js'
  import { bus } from '@/main'

  export default {
    name: 'DashboardView',

    data: () => ({
      chartData: [],
      dialogId: -1,
      showChartDialog: false,
      chartDialogData: null,

      showDashboardLoader: true,
      showStatusMessage: false,
      statusMessageType: 'info',
      noDashboardsMessage: 'You don\'t have any charts! Head over to the Sites page to discover equipment and create some charts for your Dashboard.',
      errorMessage: 'We were unable to load your Dashboard at this time. Please try again later.',
    }),

    created () {
      bus.$on('close', () => {
        this.dismissChartDialog()
      })

      bus.$on('chartDelete', (chartId) => {
        this.onChartDelete(chartId)
      })

      bus.$on('openDashboard', (chart) => {
        this.chartDialogData = chart
        this.showChartDialog = true
      })
    },

    mounted () {
      this.getUserCharts()
    },

    methods: {
      getUserCharts: function () {
        dashboardApi.getUserCharts()
          .then(response => {
            console.log(response)

            if (response.data.dashboardCompleteObjects.length === 0) {
              this.showStatusMessage = true
              this.statusMessageType = 'info'
              this.statusMessage = this.noDashboardsMessage
              this.showDashboardLoader = false
            }

            response.data.dashboardCompleteObjects.forEach(element => {
              this.showDashboardLoader = false
              this.showStatusMessage = false
              this.chartData.push({
                id: element.dashboard.id,
                title: element.dashboard.title,
                datasets: [
                  {
                    url: element.url,
                    data: element.chartPreviewDataList,
                  },
                ],
                colorList: element.dashboard.colorList,
              })
            })
            console.log('Chart Data after getUserCharts')
            console.log(this.chartData)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getUserCharts()
            } else {
              this.showStatusMessage = true
              this.statusMessageType = 'error'
              this.statusMessage = this.errorMessage
              this.showDashboardLoader = false
            }
          })
      },

      onChartDelete: function (chartId) {
        for (var i = 0; i < this.chartData.length; i++) {
          if (this.chartData[i].id === chartId) {
            this.chartData.splice(i, 1)

            if (this.chartData.length === 0) {
              this.showStatusMessage = true
              this.statusMessageType = 'info'
              this.statusMessage = this.noDashboardsMessage
              this.showDashboardLoader = false
            }

            return
          }
        }
      },

      dismissChartDialog: function () {
        this.showChartDialog = false
      },
    },
  }
</script>
